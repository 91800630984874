import React, { useState } from "react"

import { AnimatePresence, motion } from "framer-motion"
import { styled } from "styled-components"

import { expandVerticallyMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

type CurrencyInputProps = {
    parentRef?: any,
    value?: string,
    setValue?: (value: string) => void,
    label?: string,
    showsError?: boolean,
    onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void,
    errorMessage?: string,
    name?: string,
    type?: string,
}

const CurrencyInput = (props: CurrencyInputProps) => {

    const [isInputFocused, setIsInputFocused] = useState(false)

    const isLabelMinified = isInputFocused || (props.value && props.value !== '')

    const handleFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
        setIsInputFocused(true);
    }

    const handleBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
        setIsInputFocused(false);
    }

    return <Container>
        <InputEl
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={props.parentRef}
            value={props.value}
            onChange={props.onChange}
            type={props.type}
            name={props.name}
            error={props.errorMessage}
        />
        <DollarSign isLabelMinified={isLabelMinified}>$</DollarSign>
        <Label 
            error={props.errorMessage} 
            isInputFocused={isInputFocused} 
            isLabelMinified={isLabelMinified}
        >
            {props.label}
        </Label>
        <AnimatePresence mode='wait'>
            {props.errorMessage && <motion.div {...expandVerticallyMotionProps}>
                <ErrorMessage>{props.errorMessage}</ErrorMessage>
            </motion.div>}
        </AnimatePresence>
    </Container>
}

const DollarSign = styled.div<any>`
    pointer-events: none;    
    position: absolute;
    top: 0;
    left: 0;
    height: 52px;
    width: 43px;
    padding: ${props => props.isLabelMinified ? '25px' : '10px'} 16px 9px;
    display: flex;
    align-items: center;
    color: ${props => props.isLabelMinified ? styles.Color.TaekusBlack : styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    ${styles.Animation.transitionStyles}
`

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

type ContainerProps = {
    showsError?: boolean,
}

const Container = styled.div<ContainerProps>`
    min-height: 52px;
    position: relative;
`

const Label = styled.div<any>`
    pointer-events: none;
    position: absolute;
    padding: 9px ${props => props.isLabelMinified ? '16px' : '31px'};
    display: flex;
    align-items: center;
    width: 308px;
    height: ${props => props.isLabelMinified ? '30px' : '52px'};
    top: 0;
    left: 0;
    ${styles.Animation.transitionStyles}
    color: ${props => props.error ? styles.Color.TaekusRed : (props.isInputFocused ? styles.Color.TaekusPurple : styles.Color.TaekusGrey3)};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${props => props.isLabelMinified ? '10px' : '16px'};
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

type InputElProps = {
    error: any,
}

const InputEl = styled.input<InputElProps>`
    border-radius: 2px;
    border: 1px solid ${props => props.error ? styles.Color.TaekusRed : styles.Color.TaekusGrey4};
    height: 52px;
    padding: 23px 31px 9px;
    outline: 1px solid transparent;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }
    &:focus {
        outline: 1px solid ${styles.Color.TaekusPurple};
        background: linear-gradient(0deg, rgba(124, 61, 118, 0.05) 0%, rgba(124, 61, 118, 0.05) 100%), ${styles.Color.White};
        border: 1px solid transparent;
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
    }
    min-width: 308px;
    width: 100%;
    color: ${styles.Color.TaekusBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    ${styles.Animation.transitionStyles}
`

export default CurrencyInput