import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";

import alloy from '@alloyidentity/web-sdk'

import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { updateAlloy, updateStep } from "components/signup/signupSlice";
import { AlloyStatus, StepDirection } from "components/signup/types";

import Button from "components/signup/components/Button";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const KYCUpload = () => {
    const dispatch = useDispatch()

    const offer = useSelector((state: any) => state.signup.offer)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const title = `We need your help verifying your identity. `
    const text = `Thanks for signing up for Taekus. To help us verify your identity, please upload pictures of a state ID.`

    const onOpen = () => {
        alloy.open(callback)
    }

    const callback = (alloyData: any) => {
        const validStatuses = [AlloyStatus.Completed,AlloyStatus.PendingReview,AlloyStatus.PendingJourneyApplicationReview]
        if (validStatuses.includes(alloyData.status)) {
            dispatch(updateAlloy(alloyData))
            dispatch(updateStep({ step: SignupStep.Offer, direction: StepDirection.Right }))
        }
    }

    useEffect(() => {
        const production = (window as any).env.REACT_APP_DEBUG === 'false'

        const alloyInitParams = {
          key: (window as any).env.REACT_APP_ALLOY_FRONTEND_SDK_KEY,
          journeyApplicationToken: offer?.applicationToken,
          journeyToken: offer?.journeyToken,
          isNext: true,
          showHeader: true,
          production: production,
          color: {
            primary: '#1A2278',
            secondary: '#f7927c',
          },
        }

        alloy.init(alloyInitParams)

        return () => { alloy.close() }
    }, []) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='KYCUpload'
    >
        <div className="d-flex flex-column align-items-center">
            <Title>{title}</Title>
            <Text>{text}</Text>
            <Button onClick={onOpen}>Upload ID</Button>
        </div>
    </StepContainer>
}

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default KYCUpload