import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { SignupStep } from "components/signup/constants";
import { submitPrequal, updateStep } from "components/signup/signupSlice";
import { StepDirection } from "components/signup/types";

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import TaekusLoadingIcon from "components/signup/components/TaekusLoadingIcon";

import styles from "styles/styles";

const LoadingOffer = () => {
    const dispatch = useDispatch()

    const inputRef = useRef(null)

    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const offer = useSelector((state: any) => state.signup.offer)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    // Submit Prequal
    useEffect(() => {
        dispatch(submitPrequal(leadUuid))
    }, [dispatch]) // eslint-disable-line

    useEffect(() => {
        if (offer !== undefined) {
            dispatch(updateStep({
                step: (offer === false || offer?.status === 'DENIED') ? SignupStep.Denied : (offer?.needDocumentVerification ? SignupStep.KYCUpload : SignupStep.Offer),
                direction: StepDirection.Right
            }))
        }
    }, [offer]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='LoadingOffer'
    >
        <Form>
            <TaekusLoadingIcon/>
            <Title>Finding your offers...</Title>
        </Form>
    </StepContainer>
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${styles.MediaQueries.Mobile} {
        width: 100dvw;
        height: calc(100dvh - 156px);
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-top: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;   
    }
`

export default LoadingOffer