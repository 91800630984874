import React, { useEffect, useState } from "react"

import GooglePlacesAutocomplete from "react-google-places-autocomplete"

import { AnimatePresence, motion } from "framer-motion"
import { styled } from "styled-components"

import { expandVerticallyMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

type AddressInputProps = {
    value: any,
    onChange: (value: any) => void,
    autoFocus?: boolean,
    placeholder?: string,
    errorMessage?: string,
}

const AddressInput = (props: AddressInputProps) => {
    const apiKey = (window as any).env.REACT_APP_GOOGLE_PLACES_API_KEY

    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    useEffect(() => {
        // Use querySelector to enforce autoComplete property to ensure browser autofill does not trigger
        const input = document.querySelector('[id*="react-select"]');
        (input as any)?.setAttribute('autoComplete', 'new-password')
    }, [])

    const { value, onChange, placeholder } = props;

    const placeholderLabel = placeholder || 'Address'

    return <>
        <Container>
            <Label 
                isinputfocused={isFocused} 
                islabelminified={isFocused || value !== undefined}
                error={props.errorMessage}
            >
                {placeholderLabel}
            </Label>
            <GooglePlacesAutocomplete
                apiKey={apiKey}
                autocompletionRequest={{ 
                    componentRestrictions: { country: ['us'] } 
                }}
                selectProps={{
                    value: value?.value ? value : undefined,
                    onChange,
                    onFocus: handleFocus,
                    onBlur: handleBlur,
                    placeholder: (value && !value?.value) ? value?.label : ``,
                    styles: getAddressStyles(props.errorMessage),
                    autoFocus: props.autoFocus,
                    autoComplete: 'nope',
                } as any}
            />
        </Container>
        <AnimatePresence mode='wait'>
            {props.errorMessage && <ErrorMessageContainer {...expandVerticallyMotionProps}>
                <ErrorMessage>{props.errorMessage}</ErrorMessage>
            </ErrorMessageContainer>}
        </AnimatePresence>
    </>
}

const ErrorMessageContainer = styled(motion.div)`
    min-width: 100%;
    width: min-content;
`

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

type LabelProps = {
    islabelminified: boolean,
    error?: string,
    isinputfocused: boolean,
}

const Label = styled.div<LabelProps>`
    pointer-events: none;
    position: absolute;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    width: 308px;
    height: ${props => props.islabelminified ? '30px' : '52px'};
    top: 0;
    left: 0;
    ${styles.Animation.transitionStyles}
    color: ${props => props.error ? styles.Color.TaekusRed : (props.isinputfocused ? styles.Color.TaekusPurple : styles.Color.TaekusGrey3)};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${props => props.islabelminified ? '10px' : '16px'};
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    z-index: 999;
`

const Container = styled.div`
    position: relative;
`

const getAddressStyles = (errorMessage?: string) => ({
    option: (provided: any, state: any) => ({
        ...provided,
        fontFamily: 'Monument Grotesk',
        fontSize: '16px',
        backgroundColor: state.isDisabled ? styles.Color.TaekusGrey4 : (state.isFocused ? 'rgba(124, 61, 118, 0.1)' : 'white'),
        color: state.isSelected ? styles.Color.TaekusPurple : styles.Color.TaekusGrey1,
        '&:active': {
            backgroundColor: 'transparent',
        },
        '&:hover': {
            backgroundColor: 'rgba(124, 61, 118, 0.1)',
        },
        transition: `all 0.2s ease-in`,
        cursor: 'pointer',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        display: 'none',
        color: '#2A4966',
        paddingRight: '0',
        marginRight: '-1px',
        transform: 'Scale(0.80)',
    }),
    menu: (provided: any) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
        display: 'none',
        background: 'transparent',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: '0',
        margin: 0,
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: styles.Color.Black,
        margin: '0 !important',
        fontFamily: 'Monument Grotesk',
        fontSize: '16px',
        paddingTop: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        fontFamily: 'Monument Grotesk',
        fontSize: '16px',
        paddingTop: '16px',
        margin: 0,
    }),
    menuList: (provided: any, state: any) => ({
        ...provided,
        padding: 0,
    }),
    input: (provided: any, state: any) => ({
        ...provided,
        margin: 0,
        padding: `19px 0 10px 0`,
        height: '50px'
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        cursor: 'text',
        height: '52px',
        minWidth: '308px',
        width: '100%',
        outline: 'none',
        color: '#999898',
        margin: '0 !important',
        padding: '0 16px',
        boxShadow: state.isSelected || state.isFocused ? `none` : 'none',
        background: state.isFocused ? `linear-gradient(0deg, rgba(124, 61, 118, 0.05) 0%, rgba(124, 61, 118, 0.05) 100%), ${styles.Color.White}` : styles.Color.White,
        fontFamily: 'Monument Grotesk',
        fontSize: '16px',
        letterSpacing: '0.32px',
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        border: (errorMessage ? `1px solid ${styles.Color.TaekusRed}` : (state.isFocused ? '1px solid #7C3D76' : `1px solid ${styles.Color.TaekusGrey4}`)) + ` !important`,
        '&:hover': {
            boxShadow: '0px 0px 16px 0px rgba(124, 61, 118, 0.15)',
            border: '1px solid transparent',
        },
        '&:focus': {
            border: '1px solid #7C3D76',
            boxShadow:' 0px 0px 16px 0px rgba(124, 61, 118, 0.15)',
        },
        transition: 'all 0.2s ease-in'
    }),
})

export default AddressInput