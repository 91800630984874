import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { styled } from "styled-components"

import { Locale, USDCurrencyOptions } from "utils/constants";
import { formatPhoneNumber } from "utils/utils";

import { SignupStep } from "components/signup/constants";
import messages from "components/signup/messages";
import { updateStep } from "components/signup/signupSlice";
import { ProductType, CompanyStructure, HousingType, StepDirection, ProductCategory } from "components/signup/types";

import Button from "components/signup/components/Button";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const ReviewInfo = () => {
    const dispatch = useDispatch()

    const step = useSelector((state: any) => state.signup.step)
    const prevStep = useSelector((state: any) => state.signup.prevStep)
    const application = useSelector((state: any) => state.signup.application)
    const leadExists = useSelector((state: any) => state.signup.leadExists)
    const userExists = useSelector((state: any) => state.signup.userExists)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isBusiness = selectedProduct?.productCategory === ProductCategory.Business
    const isSoleProp = application.companyStructure === CompanyStructure.SoleProp
    const subtitle = leadExists ? (prevStep === SignupStep.EditInfo ? "" : "We found a recent in-progress application from you. ") + "Is this information still correct?" : "We’ve pre-filled your information for you. Is all of it correct?"

    // These variables are state values instead of consts to avoid changing during unmount animation
    const [isInitialReview, setIsInitialReview] = useState(step === SignupStep.InitialReview) // eslint-disable-line
    const [showSubtitle, setShowSubtitle] = useState(step !== SignupStep.FinalReview) // eslint-disable-line

    const handleEdit = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        dispatch(updateStep({ 
            step: SignupStep.EditInfo,
            prevStep: step,
            direction: StepDirection.Down
        }))
    }

    const handleEditBusiness = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        dispatch(updateStep({ 
            step: SignupStep.EditBusinessInfo,
            prevStep: step,
            direction: StepDirection.Down
        }))
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        const isCreditProduct = selectedProduct.productType === ProductType.Credit
        const isBusinessProduct = selectedProduct.productCategory === ProductCategory.Business
        const nextStep = userExists ? (isCreditProduct ? SignupStep.Income : (isBusinessProduct ? SignupStep.BizType : SignupStep.FinalReview)) : SignupStep.Email

        dispatch(updateStep({ 
            step: step === SignupStep.InitialReview ? nextStep : SignupStep.LoadingOffer, 
            direction: StepDirection.Right,
            prevStep: step,
        }))
    }

    const isAddressUnpopulated = !application.addressStreet || !application.addressCity || !application.addressState || !application.addressZip

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='ReviewInfo'
    >
        <Form onSubmit={handleSubmit}>
            <Title>{application.firstName}, is this information correct?</Title>
            {showSubtitle && <Text>{subtitle}</Text>}
            {isInitialReview || selectedProduct.productCategory === ProductCategory.Personal ? <InfoContainer>
                <InfoColumn style={{width: 'unset'}}>
                    <InfoRow>
                        <InfoLabel>Legal Name</InfoLabel>
                        <InfoValue>{application.firstName} {application.lastName}</InfoValue>
                    </InfoRow>
                    <LineBreak/>
                    <InfoRow>
                        <InfoLabel>Birthday</InfoLabel>
                        <InfoValue>{moment(application.birthDate).format('MMM D, YYYY')}</InfoValue>
                    </InfoRow>
                    <InfoRow>
                        <InfoLabel>SSN</InfoLabel>
                        <InfoValue>{(application.ssn?.slice(-4) || application.ssnLastFour) ? `XXX-XX-` + (application.ssn?.slice(-4) || application.ssnLastFour) : ''}</InfoValue>
                    </InfoRow>
                    <LineBreak/>
                    <InfoRow>
                        <InfoLabel>Address</InfoLabel>
                        <InfoValue>{isAddressUnpopulated ? '' : (application.addressStreet + ',\n' + application.addressCity + ', ' + application.addressState + ' ' + application.addressZip)}</InfoValue>
                    </InfoRow>
                    {(application.addressLineTwo && application.addressLineTwo !== '') && <InfoRow>
                        <InfoLabel>Address Line 2</InfoLabel>
                        <InfoValue>{application.addressLineTwo}</InfoValue>
                    </InfoRow>}
                    <InfoRow>
                        <InfoLabel>Phone Number</InfoLabel>
                        <InfoValue>{`+1 ${formatPhoneNumber(application.phoneNumber?.slice(2))}`}</InfoValue>
                    </InfoRow>
                    {application.email && !isInitialReview && <InfoRow>
                        <InfoLabel>E-mail</InfoLabel>
                        <InfoValue>{application.email}</InfoValue>
                    </InfoRow>}
                    {!isInitialReview && (!!Number(application.totalAnnualIncome) || application.residenceType) && <LineBreak/>}
                    {!isInitialReview && !!Number(application.totalAnnualIncome) && <InfoRow>
                        <InfoLabel>Annual Income</InfoLabel>
                        <InfoValue>{Number(application.totalAnnualIncome).toLocaleString(Locale.English, USDCurrencyOptions)?.slice(0,-3)}</InfoValue>
                    </InfoRow>}
                    {!isInitialReview && !!application.residenceType && <InfoRow>
                        <InfoLabel>Housing</InfoLabel>
                        <InfoValue>{messages.Housing[application.residenceType as HousingType] + (application.residenceType === HousingType.Rent ? `, ${Number(application.monthlyHousingPayment).toLocaleString(Locale.English, USDCurrencyOptions)?.slice(0,-3)}/mo` : '')}</InfoValue>
                    </InfoRow>}
                </InfoColumn>
            </InfoContainer> : <InfoContainer>
                <InfoColumn>
                    <InfoStack>
                        <ExpandedInfoLabel>Legal Name</ExpandedInfoLabel>
                        <InfoValue>{application.firstName} {application.lastName}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>Birthday</ExpandedInfoLabel>
                        <InfoValue>{moment(application.birthDate).format('MMM D, YYYY')}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>SSN</ExpandedInfoLabel>
                        <InfoValue>{`XXX-XX-` + (application.ssn?.slice(-4) || application.ssnLastFour)}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>Address</ExpandedInfoLabel>
                        <InfoValue>{application.addressStreet + ', ' + application.addressCity + ', ' + application.addressState + ' ' + application.addressZip}</InfoValue>
                    </InfoStack>
                    {(application.addressLineTwo && application.addressLineTwo !== '') && <InfoStack>
                        <ExpandedInfoLabel>Address Line 2</ExpandedInfoLabel>
                        <InfoValue>{application.addressLineTwo}</InfoValue>
                    </InfoStack>}
                    <InfoStack>
                        <ExpandedInfoLabel>Phone Number</ExpandedInfoLabel>
                        <InfoValue>{`+1 ${formatPhoneNumber(application.phoneNumber?.slice(2))}`}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>E-mail</ExpandedInfoLabel>
                        <InfoValue>{application.email}</InfoValue>
                    </InfoStack>
                    {!!Number(application.totalAnnualIncome) && <InfoStack>
                        <ExpandedInfoLabel>Annual Income</ExpandedInfoLabel>
                        <InfoValue>{Number(application.totalAnnualIncome).toLocaleString(Locale.English, USDCurrencyOptions)?.slice(0,-3)}</InfoValue>
                    </InfoStack>}
                    {!!application.residenceType && <InfoStack>
                        <ExpandedInfoLabel>Housing</ExpandedInfoLabel>
                        <InfoValue>{messages.Housing[application.residenceType as HousingType] + (application.residenceType === HousingType.Rent ? `, ${Number(application.monthlyHousingPayment).toLocaleString(Locale.English, USDCurrencyOptions)?.slice(0,-3)}/mo` : '')}</InfoValue>
                    </InfoStack>}
                </InfoColumn>
                <InfoColumn>
                    {application.companyStructure !== CompanyStructure.SoleProp && <InfoStack>
                        <ExpandedInfoLabel>Business Name</ExpandedInfoLabel>
                        <InfoValue>{application.companyName || '-'}</InfoValue>
                    </InfoStack>}
                    <InfoStack>
                        <ExpandedInfoLabel>Business Type</ExpandedInfoLabel>
                        <InfoValue>{messages.BizTypes[application.companyStructure as CompanyStructure]}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>EIN</ExpandedInfoLabel>
                        <InfoValue>{application.taxIdentificationNumber || '-'}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>Business Address</ExpandedInfoLabel>
                        <InfoValue>{`${application.businessAddressStreet}\n${application.businessAddressCity}, ${application.businessAddressState} ${application.businessAddressZip}`}</InfoValue>
                    </InfoStack>
                    {(application.businessAddressLineTwo && application.businessAddressLineTwo !== '') && <InfoStack>
                        <ExpandedInfoLabel>Address Line 2</ExpandedInfoLabel>
                        <InfoValue>{application.businessAddressLineTwo}</InfoValue>
                    </InfoStack>}
                    <InfoStack>
                        <ExpandedInfoLabel>Industry Type</ExpandedInfoLabel>
                        <InfoValue>{application.naicsCode}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>DBA</ExpandedInfoLabel>
                        <InfoValue>{application.dbaName || '-'}</InfoValue>
                    </InfoStack>
                    <InfoStack>
                        <ExpandedInfoLabel>Established Date</ExpandedInfoLabel>
                        <InfoValue>{moment(application.formationDate).format('MMM D, YYYY')}</InfoValue>
                    </InfoStack>
                    {!isSoleProp && <InfoStack>
                        <ExpandedInfoLabel>% of Ownership</ExpandedInfoLabel>
                        <InfoValue>{application.businessPercentOwnership}%</InfoValue>
                    </InfoStack>}
                </InfoColumn>
            </InfoContainer>}
            <ButtonRow>
                <ButtonContainer>
                    {!userExists && <Button
                        key='secondary' 
                        secondary 
                        type="button" 
                        onClick={handleEdit}
                    >
                        {(!isInitialReview && isBusiness) ? 'Edit Personal Info' : 'Edit'}
                    </Button>}
                </ButtonContainer>
                {(!isInitialReview && isBusiness) && <ButtonContainer>
                    {!userExists && <Button
                        key='secondary' 
                        secondary 
                        type="button" 
                        onClick={handleEditBusiness}
                    >
                        Edit Business Info
                    </Button>}
                </ButtonContainer>}
                <ButtonContainer>
                    <Button type="submit">Looks Good</Button>
                </ButtonContainer>
            </ButtonRow>
        </Form>
    </StepContainer>
}

const ButtonContainer = styled.div`
`

const ButtonRow = styled.div`
    display: flex;
    gap: 12px;
    ${styles.MediaQueries.Mobile} {
        flex-wrap: wrap;
        justify-content: space-around;
    }
`

const ExpandedInfoLabel = styled.div`
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.1px;
    margin-bottom: 8px;
`

const InfoStack = styled.div`
    display: flex;
    flex-direction: column;
    &:not(&:last-child) {
        margin-bottom: 16px;
    }
`

const InfoColumn = styled.div`
    display: flex;
    width: 276px;
    flex-direction: column;
    &:not(&:first-child) {
        margin-left: 16px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-left: 0 !important;
        margin-bottom: 16px;
    }
`

const LineBreak = styled.div`
    width: 100%;
    height: 1px;
    background: ${styles.Color.TaekusGrey4};
    margin: 24px 0;
`

const InfoLabel = styled.div`
    width: 140px;
    box-sizing: border-box;
    padding-right: 100px;
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
    white-space: nowrap;
    ${styles.MediaQueries.Mobile} {
        font-size: 10px;
    }
`

const InfoValue = styled.div`
    white-space: pre-line;
    min-width: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    flex: 1;
    box-sizing: border-box;
    color: ${styles.Color.TaekusGrey1};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
`

const InfoRow = styled.div`
    display: flex;
    min-width: 0;
    width: max-content;
    &:not(&:last-child) {
        margin-bottom: 8px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const InfoContainer = styled.div`
    display: flex;
    padding: 32px;
    background: ${styles.Color.White};
    border-radius: 4px;
    width: min-content;
    margin: 32px 0 48px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;
        flex-direction: column; 
    }
    ${styles.Scrollbar.TaekusGrey}
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-top: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Form = styled.form`
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 100%;
   ${styles.MediaQueries.Mobile} {
        max-height: 100%;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default ReviewInfo