import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import moment from "moment"

import { motion } from "framer-motion"
import styled from "styled-components"

import { FaRegTimesCircle } from 'react-icons/fa'

import { getCountryOptions } from "components/common/bookingConstants"
import { validateString } from "utils/utils"

import { Actions as BookingActions } from 'redux/features/flightBook'

import Spinner from "components/common/Spinner"
import StatusDisplay from "components/common/StatusDisplay"

import {
    PassengerValidationErrorLabels,
    VALID_IMAGE_TYPES,
    defaultTravelerDetails,
    defaultTravelerDetailsErrors
} from "components/pages/Rewards/Flights/constants"
import { PassengerFieldToggles } from "components/pages/Rewards/Flights/toggles"
import { TravelerDetails, TravelerDetailsErrors } from "components/pages/Rewards/Flights/types"
import { validateDOB, validateKTN, validatePassportExpDate, validatePassportNumber } from "components/pages/Rewards/Flights/FlightBooking/util"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as Checkmark } from "assets/svg/Checkmarks/Checkmark.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";
import { ReactComponent as File } from "assets/svg/File.svg";
import { ReactComponent as Reload } from "assets/svg/Reload.svg";
import { ReactComponent as Upload } from "assets/svg/Upload.svg";

import 'components/pages/Rewards/phoneInputStyles.css'

type ManageTravelerProps = {
    /* 
     *  A TravelerDetails object that represents an existing traveler that is being edited.
     *  If creating a new user, this should be falsy (and ignored in this file).
     */
    inspectedTraveler: TravelerDetails,
    /*
     *  A function to be called when the save button on this form is clicked.
     */
    onSave: (passenger: TravelerDetails) => void,
    /*
     *  A function to be called when the user discards their changes.
     */
    onClose: () => void,
    /*
     *  A boolean that represents if this is being used in the booking flow, or not.
     *  If true, then certain passport fields will only be shown conditionally.
     */
    isBooking?: boolean,
}

const ManageTraveler = (props: ManageTravelerProps) => {
    const dispatch = useDispatch()
    
    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    const flightBook = useSelector((state: any) => state.flightBook)

    // Component state
    const [passengerDetails, setPassengerDetails] = useState(props.inspectedTraveler || defaultTravelerDetails)
    const [showPassengerErrors, setShowPassengerErrors] = useState(false)
    const [passengerErrors, setPassengerErrors] = useState(defaultTravelerDetailsErrors)
    const [showRedressNo, setShowRedressNo] = useState(false);
    const [imageName, setImageName] = useState('Image')

    const hiddenFileInput = useRef<HTMLInputElement>(null); // used to hide passport image input and show button instead
    const isPassportImageRequired = props.isBooking && priceDetail?.reservations?.some((reservation: any) => reservation.transferPartnerData.partnerCode === 'VN')
    const displayedError = flightBook.createTravelerErrors ? "Failed to save Traveler info. Please try again" : (showPassengerErrors ? Object.values(passengerErrors).find((error?: string) => !!error) : undefined)
    const policyLink = 'https://taekus.com/legal/privacy-policy'
    const tsaLink = 'https://www.tsa.gov/travel/security-screening'

    // Field level toggles
    const requiredFields = priceDetail?.requiredFields
    const disallowedFields = priceDetail?.disallowedFields
    const redressEnabled = disallowedFields && !disallowedFields.includes(PassengerFieldToggles.RedressNumber)
    const citizenshipCountryEnabled = !requiredFields || requiredFields.includes(PassengerFieldToggles.CitizenshipCountryCode)
    const residenceCountryEnabled = !requiredFields || requiredFields.includes(PassengerFieldToggles.ResidenceCountryCode)

    const handleDetailChange = (ev: React.ChangeEvent) => {
        const updatedDetails = {
            ...passengerDetails,
            [ev.target.id]: (ev.target as HTMLInputElement).value,
        }

        setPassengerDetails(updatedDetails)
        isPassengerDetailsValid(updatedDetails)
    }

    // Close individual traveler view and clear passport image state from Redux
    const handleClose = () => {
        const { onClose } = props;
        dispatch(BookingActions.clearPassportImage())
        onClose();
    }

    const handleSaveButton = () => {
        const { onSave } = props;

        if (isPassengerDetailsValid(passengerDetails)) {
            setShowPassengerErrors(false)
            onSave(passengerDetails)
            dispatch(BookingActions.clearPassportImage())
        } else {
            setShowPassengerErrors(true)
        }
    }

    const handleImageUploadClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click()
        }
    }

    const clearPassengerDetails = () => {
        setPassengerDetails(defaultTravelerDetails)
        setShowPassengerErrors(false)
    }

    const isSavePassengerLoading = flightBook.createTravelerIsLoading || flightBook.updateTravelerIsLoading

    const removePassportImage = () => {
        dispatch(BookingActions.clearPassportImage())
        setPassengerDetails({
            ...passengerDetails,
            passportImage: undefined,
        })
        isPassengerDetailsValid({
            ...passengerDetails,
            passportImage: undefined
        })
    }

    const isPassengerDetailsValid = (passengerDetails: TravelerDetails) => {
        const { isBooking } = props;

        const updatedErrors: TravelerDetailsErrors = {
            firstName: validateString(passengerDetails.firstName) ? undefined : PassengerValidationErrorLabels.FirstName.MissingValue,
            lastName: validateString(passengerDetails.lastName) ? undefined : PassengerValidationErrorLabels.LastName.MissingValue,
            dateOfBirth: validateDOB(passengerDetails.dateOfBirth),
            gender: validateString(passengerDetails.gender) ? undefined : PassengerValidationErrorLabels.Gender.MissingValue,
            knownTravellerNumber: validateKTN(passengerDetails.knownTravellerNumber),
            passportNumber: (!isBooking || priceDetail.isDomesticItinerary) ? undefined : validatePassportNumber(passengerDetails.passportNumber),
            passportCountry: (!isBooking || priceDetail.isDomesticItinerary || validateString(passengerDetails.passportCountry)) ? undefined : PassengerValidationErrorLabels.Passport.Country.MissingValue,
            passportExpDate: (!isBooking || priceDetail.isDomesticItinerary) ? undefined : validatePassportExpDate(passengerDetails.passportExpDate),
            passportImage: !isPassportImageRequired || !!passengerDetails.passportImage ? undefined : PassengerValidationErrorLabels.Passport.Image.Missing,
            citizenshipCountry: (!citizenshipCountryEnabled || !isBooking || priceDetail.isDomesticItinerary || validateString(passengerDetails.citizenshipCountry)) ? undefined : PassengerValidationErrorLabels.CitizenshipCountry.MissingValue,
            residenceCountry: (!residenceCountryEnabled || !isBooking || priceDetail.isDomesticItinerary || validateString(passengerDetails.residenceCountry)) ? undefined : PassengerValidationErrorLabels.ResidenceCountry.MissingValue
        }

        setPassengerErrors(updatedErrors)
        return !Object.values(updatedErrors).some(error => error !== undefined)
    }

    const handlePassportImageUpload = (event: any) => {
        const imageFile = event.target.files[0]

        // get file name for uploaded file
        setImageName(event.target.value.replace(/.*[/\\]/, ''))

        const errors: string[] = []
        if (imageFile?.size > 5000000) {
            errors.push(PassengerValidationErrorLabels.PassportImage.ExceedsSize)
        }
        if (!VALID_IMAGE_TYPES.includes(imageFile?.type)) {
            errors.push(PassengerValidationErrorLabels.PassportImage.InvalidType)
        }

        if (!!errors.length) {
            setPassengerErrors({
                ...passengerErrors,
                passportImage: errors[0]
            })
        } else {
            const formData = new FormData();
            formData.append("image", imageFile);
            dispatch(BookingActions.uploadPassportImage(formData))
        }

        // Reset selected file to avoid weird behavior on repeat uploads
        event.target.value = ''
        return
    }

    // Copy passport image from redux state to passenger details and validate
    useEffect(() => {
        if (flightBook.passportImage?.uuid !== undefined) {
            setPassengerDetails({
                ...passengerDetails,
                passportImage: flightBook.passportImage.uuid
            })
            isPassengerDetailsValid({
                ...passengerDetails,
                passportImage: flightBook.passportImage.uuid
            })
        }
    }, [flightBook.passportImage]) // eslint-disable-line

    const { inspectedTraveler } = props;

    return <Container key='edit' {...fadeInOutMotionProps}>
        <Header>
            <BackButton onClick={handleClose}>{`< Back to All Travelers`}</BackButton>
            <ClearTravelerInfoButton onClick={clearPassengerDetails}>
                <StyledReload/>
                <div>Clear Traveler Info</div>
            </ClearTravelerInfoButton>
        </Header>
        <EditTravelerTitle>{inspectedTraveler ? 'Edit' : 'Add New'} Traveler</EditTravelerTitle>
        <Description>You will not be able to change the names on the ticket after booking. Please ensure that details match government-issued IDs.</Description>
        <LinksContainer>
            <ExternalLink rel="noopener noreferrer" target='_blank' href={tsaLink}>TSA Secure Flight rules</ExternalLink>
            {!isMobile && <ExternalLinkSeparator>•</ExternalLinkSeparator>}
            <ExternalLink rel="noopener noreferrer" target='_blank' href={policyLink}>Taekus Privacy Policy</ExternalLink>
        </LinksContainer>
        {displayedError && <ErrorDisplayContainer>
            <StatusDisplay
                isLoading={false}
                isError={true}
                label={displayedError}
            />
        </ErrorDisplayContainer>}
        <EditTravelerSubtitle>Personal Info</EditTravelerSubtitle>
        <PassengerDetailsContainer>
            <PassengerInputContainer error={showPassengerErrors && !!passengerErrors.firstName}>
                <PassengerInput
                    name='firstname'
                    error={showPassengerErrors && !!passengerErrors.firstName}
                    value={passengerDetails.firstName || ''}
                    onChange={handleDetailChange}
                    id='firstName'
                />
                <PassengerLabel>First Name</PassengerLabel>
            </PassengerInputContainer>
            <PassengerInputContainer>
                <PassengerInput
                    name='middlename'
                    value={passengerDetails.middleName || ''}
                    onChange={handleDetailChange}
                    id='middleName'
                />
                <PassengerLabel>Middle Name</PassengerLabel>
            </PassengerInputContainer>
            <PassengerInputContainer error={showPassengerErrors && !!passengerErrors.lastName}>
                <PassengerInput
                    name='lastname'
                    error={showPassengerErrors && !!passengerErrors.lastName}
                    value={passengerDetails.lastName || ''}
                    onChange={handleDetailChange}
                    id='lastName'
                />
                <PassengerLabel>Last Name</PassengerLabel>
            </PassengerInputContainer>
            <PassengerInputContainer error={showPassengerErrors && !!passengerErrors.dateOfBirth}>
                <PassengerInput
                    error={showPassengerErrors && !!passengerErrors.dateOfBirth}
                    type='date'
                    max={moment().format('YYYY-MM-DD')}
                    value={passengerDetails.dateOfBirth}
                    onChange={handleDetailChange}
                    id='dateOfBirth'
                />
                <PassengerLabel>Date of Birth</PassengerLabel>
            </PassengerInputContainer>
            <PassengerInputContainer error={showPassengerErrors && !!passengerErrors.gender}>
                <DetailSelect error={showPassengerErrors && !!passengerErrors.gender} value={passengerDetails.gender || ''} onChange={handleDetailChange} id='gender'>
                    <option value='' selected disabled>Select Gender</option>
                    <option value='M'>Male</option>
                    <option value='F'>Female</option>
                </DetailSelect>
                <PassengerLabel>Gender</PassengerLabel>
            </PassengerInputContainer>
        </PassengerDetailsContainer>
        {showRedressNo && <PassengerDetailsContainer>
            <PassengerInputContainer>
                <PassengerInput
                    value={passengerDetails.knownTravellerNumber || ''}
                    onChange={handleDetailChange}
                    id='knownTravellerNumber'
                />
                <PassengerLabel>Known Traveler Number (KTN)</PassengerLabel>
            </PassengerInputContainer>
            {redressEnabled && <PassengerInputContainer>
                <PassengerInput
                    value={passengerDetails.redressNumber || ''}
                    onChange={handleDetailChange}
                    id='redressNumber'
                />
                <PassengerLabel>Redress Number</PassengerLabel>
            </PassengerInputContainer>}
        </PassengerDetailsContainer>}
        {!priceDetail.isDomesticItinerary && <PassengerDetailsContainer>
            <PassengerInputContainer error={false}>
                <PassengerInput
                    error={showPassengerErrors && !!passengerErrors.passportNumber}
                    value={passengerDetails.passportNumber || ''}
                    onChange={handleDetailChange}
                    id='passportNumber'
                />
                <PassengerLabel>Passport Number</PassengerLabel>
            </PassengerInputContainer>
            <PassengerInputContainer error={false}>
                <PassengerInput
                    type='date'
                    error={showPassengerErrors && !!passengerErrors.passportExpDate}
                    value={passengerDetails.passportExpDate || ''}
                    onChange={handleDetailChange}
                    id='passportExpDate'
                />
                <PassengerLabel>Passport Expiration Date</PassengerLabel>
            </PassengerInputContainer>
            <PassengerInputContainer error={false}>
                <DetailSelect error={showPassengerErrors && !!passengerErrors.passportCountry} value={passengerDetails.passportCountry || ''} onChange={handleDetailChange} id='passportCountry'>
                    <option value='' selected disabled>Select Country</option>
                    {getCountryOptions().map((country, index) => <option key={index} value={country.value}>{country.label}</option>)}
                </DetailSelect>
                <PassengerLabel>Passport Country</PassengerLabel>
            </PassengerInputContainer>
            {residenceCountryEnabled && 
                <PassengerInputContainer error={false}>
                    <DetailSelect error={showPassengerErrors && !!passengerErrors.residenceCountry} value={passengerDetails.residenceCountry || ''} onChange={handleDetailChange} id='residenceCountry'>
                        <option value='' selected disabled>Select Country</option>
                        {getCountryOptions().map((country, index) => <option key={index} value={country.value}>{country.label}</option>)}
                    </DetailSelect>
                    <PassengerLabel>Residence Country</PassengerLabel>
                </PassengerInputContainer>
            }
            {citizenshipCountryEnabled &&
                <PassengerInputContainer error={false}>
                    <DetailSelect error={showPassengerErrors && !!passengerErrors.citizenshipCountry} value={passengerDetails.citizenshipCountry || ''} onChange={handleDetailChange} id='citizenshipCountry'>
                        <option value='' selected disabled>Select Country</option>
                        {getCountryOptions().map((country, index) => <option key={index} value={country.value}>{country.label}</option>)}
                    </DetailSelect>
                    <PassengerLabel>Citizenship Country</PassengerLabel>
                </PassengerInputContainer>
            }
        </PassengerDetailsContainer>}
        <AdditionalDetailLinks>
            {!showRedressNo && <AddTravelerDetailLink onClick={() => { setShowRedressNo(true) }}>Add Known Traveler {redressEnabled && "or Redress Number"}</AddTravelerDetailLink>}
            {/* <AddTravelerDetailLink>Add Frequent Flyer Memberships</AddTravelerDetailLink> */}
            {/* {!showPassport && <AddTravelerDetailLink onClick={() => { setShowPassport(true) }}>Add Passport Info</AddTravelerDetailLink>} */}
        </AdditionalDetailLinks>
        {(isPassportImageRequired || !props.isBooking) && <div>
            <EditTravelerSubtitle>
                {passengerDetails.passportImage ? <>
                    ID Uploaded
                    <StyledCheckmark/>
                </> : 'ID Upload'}
            </EditTravelerSubtitle>
            <IDUploadButtonContainer>
                {flightBook.passportImage?.isLoading ? <Spinner size={20}/> : (passengerDetails.passportImage ? <PassportImageFile>
                    <div className="d-flex justify-content-center">
                        <StyledFile/>
                        {imageName}
                    </div>
                    <StyledClose onClick={removePassportImage}/>
                </PassportImageFile> : <>
                    <UploadIDButton onClick={handleImageUploadClick}>
                        <StyledUploadIcon />
                        <UploadIDButtonLabel>Upload ID</UploadIDButtonLabel>
                    </UploadIDButton>
                    <UploadRequirements>
                        <div>Supported formats: JPG, PNG</div>
                        <div>Maximum file size: 10 MB</div>
                    </UploadRequirements>
                </>)}
            </IDUploadButtonContainer>
            <UploadStatus uploadFailed={!!passengerErrors.passportImage}>
                <ErrorIcon/>
                <div>{passengerErrors.passportImage}</div>
            </UploadStatus>
        </div>}
        <ButtonsContainer>
            <CancelButton onClick={handleClose} disabled={isSavePassengerLoading}>Cancel</CancelButton>
            {
                <SaveButton onClick={handleSaveButton} isLoading={isSavePassengerLoading}>
                    {isSavePassengerLoading ? <Spinner size={20}/> : "Save & Add Traveler"}
                </SaveButton>
            }
        </ButtonsContainer>
        <InvisibleFileInput
            ref={hiddenFileInput}
            type="file"
            id='passportImage'
            accept='.jpg,.png'
            onChange={handlePassportImageUpload}
        />
    </Container>
}

const ErrorDisplayContainer = styled.div`
    margin-bottom: ${styles.Spacing.S};
`

const InvisibleFileInput = styled.input`
    display: none;
`

const StyledClose = styled(Close)`
    cursor: pointer;
    height: 20px;
    width: 20px;
`

const StyledFile = styled(File)`
    height: 20px;
    width: 16px;
    margin-right: 14px;
`

const StyledCheckmark = styled(Checkmark)`
    fill: #56BC39;
    stroke: #56BC39;
    margin-left: 8px;
    width: 18px;
    height: auto;
`

const PassportImageFile = styled.div`
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    width: 760px;
    max-width: 100%;
    color: #000000;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

const ButtonsContainer = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        justify-content: end;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const IDUploadButtonContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 44px;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        align-items: start;
    }
`

const Container = styled(motion.div)`
    ${styles.MediaQueries.Mobile} {
        padding: 15px;
    }
`

const ClearTravelerInfoButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    ${styles.Animation.transitionStyles}
    &:hover {
        border-bottom: 1px solid black;
    }
`

const StyledUploadIcon = styled(Upload)`
    width: ${styles.Spacing.S};
    height: auto;
    margin-right: ${styles.Spacing.XS};
`

const PassengerDetailsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const AdditionalDetailLinks = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 50px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 10px 0 20px;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${styles.Spacing.XS};
`

const LinksContainer = styled.div`
    display: flex;
    margin-bottom: ${styles.Spacing.S};
    color: #6B6B6B;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        font-size: 14px;
    }
`

const ExternalLinkSeparator = styled.div`
    margin: 0 4px;
`

const StyledReload = styled(Reload)`
    width: 12px;
    height: 12px;
    margin-right: 10px;
`

const ExternalLink = styled.a`
    color: ${styles.Color.TaekusPurple};
    text-decoration: underline;
    &:hover {
        color: ${styles.Color.TaekusPurple};
    }
`

type UploadStatusProps = {
    uploadFailed: boolean,
}

const UploadStatus = styled.div<UploadStatusProps>`
    display: flex;
    align-items: center;
    color: #EA3323;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    
    ${styles.Animation.transitionStyles}
    opacity: ${props => props.uploadFailed ? 1 : 0};
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 50px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 10px;
    }
`

const DetailSelect = styled.select<PassengerInputProps>`
    background-color: rgba(0,0,0,0);
    padding: 1px 2px 7px;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.02em;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 100%;
    height: 38px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${props => props.error ? 'red' : styles.Color.Grey};
    color: ${props => props.error ? 'red' : styles.Color.Black};
    opacity: ${props => props.value === '' ? 0.5 : 1};
    outline: 0;
`

const UploadRequirements = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
`

const UploadIDButtonLabel = styled.div`
    ${styles.MediaQueries.Mobile} {
        flex: 1;
    }
`

const UploadIDButton = styled.button`
    display: flex;
    align-items: center;
    border: 1px solid ${styles.Color.TaekusPurple};
    color: ${styles.Color.TaekusCream};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    background-color: ${styles.Color.TaekusPurple};
    padding: 10px 25px;
    border-radius: 2px;
    fill: ${styles.Color.TaekusCream};
    ${styles.Animation.transitionStyles}
    &:hover {
        fill: ${styles.Color.TaekusPurple} !important;
        color: ${styles.Color.TaekusPurple};
        background-color: transparent;
    }
    ${styles.MediaQueries.Desktop} {
        margin-right: 30px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 10px;
    }
`

const AddTravelerDetailLink = styled.div`
    width: fit-content;
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.12px;
    text-decoration-line: underline;
    cursor: pointer;
    margin-bottom: 2px;
`

const EditTravelerTitle = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.24px;
`

const EditTravelerSubtitle = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.16px;
    margin-bottom: 15px;
`

const BackButton = styled.div`
    cursor: pointer;
    color: ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 146%; /* 14.6px */
    letter-spacing: 0.1px;
    text-decoration-line: underline;
`

const CancelButton = styled.button`
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    height: 42px;
    border-radius: 2px;
    border: 2px solid #000;
    color: #000;
    text-align: right;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-right: 40px;
    ${styles.Animation.transitionStyles}
    &:hover {
        color: ${styles.Color.TaekusCream};
        background-color: #000;
    }
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin-bottom: 10px;
    }
`
type SaveButtonProps = {
    isLoading?: boolean,
}

const SaveButton = styled.button<SaveButtonProps>`
    display: flex;
    background-color: ${props => props.isLoading ? "transparent" : "#000"};
    color: ${styles.Color.TaekusCream};
    justify-content: center;
    align-items: center;
    height: 42px;
    border-radius: 2px;
    border: 2px solid #000;
    text-align: right;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    ${styles.Animation.transitionStyles}
    &:hover {
        color: #000;
        background-color: transparent;
    }
    ${styles.MediaQueries.Desktop} {
        width: 360px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const Description = styled.div`
    color: #6B6B6B;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

const ErrorIcon = styled(FaRegTimesCircle)`
    margin-right: ${styles.Spacing.XS};
    height: 20px;
    width: auto;
`

type PassengerInputContainerProps = {
    marginRight?: string,
    error?: boolean,
}

const PassengerInputContainer = styled.div<PassengerInputContainerProps>`
    overflow: hidden;
    ${props => props.marginRight && `margin-right: ${props.marginRight};`}
    color: ${props => props.error ? 'red' : styles.Color.NearBlack};
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin-top: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        width: ${1 / 3 * 100}%;
        min-width: 300px;
        padding-right: 40px;
        margin-bottom: 20px;
    }
    ${styles.Animation.transitionStyles}
`

const PassengerLabel = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
    margin-top: 4px;
    opacity: 0.5;
`

type PassengerInputProps = {
    error?: boolean;
    value?: any,
}

const PassengerInput = styled.input<PassengerInputProps>`
    background-color: rgba(0,0,0,0);
    padding: 1px 2px 7px;
    font-size: 20px;
    line-height: 140%;
    height: 38px;
    letter-spacing: 0.02em;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${props => props.error ? 'red' : styles.Color.Grey};
    color: ${props => props.error ? 'red' : styles.Color.Black};
    outline: 0;
    text-overflow: ellipsis;
    opacity: ${props => props.value === '' ? 0.5 : 1};
    white-space: nowrap;
    &::-webkit-date-and-time-value {
        color: black;
        text-align: start;
    }
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    ${styles.Animation.transitionStyles}
`

export default ManageTraveler