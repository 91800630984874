import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { parseGoogleAddress } from "components/common/util";
import { useIsFirstRender } from "hooks/useIsFirstRender";

import { SignupStep, taxCategories } from "components/signup/constants";
import { StringError } from "components/signup/errors";
import messages from "components/signup/messages";
import { updateApplication, updateLead, updateStep } from "components/signup/signupSlice";
import { CompanyStructure, StepDirection } from "components/signup/types";
import {
    validateString,
    validateBusinessStructure,
    validateBusinessIndustry,
    validateEIN,
    validateFormationDate,
    validatePercentOwnership,
    validateAddress
} from "components/signup/validators";

import AddressInput from "components/signup/components/AddressInput";
import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import Select from "components/signup/components/Select";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";

const companyStructureList = [
    CompanyStructure.SoleProp,
    CompanyStructure.LLC,
    CompanyStructure.cCorp,
    CompanyStructure.sCorp,
]

const inputFadeInOutMotionProps = {
    initial:{ height: 0, opacity: 0, margin: 0 },
    exit:{ height: 0, opacity: 0, margin: 0 },
    animate:{ height: 'auto', opacity: 1, marginTop: '12px' },
}

const EditBusinessInfo = () => {
    const dispatch = useDispatch()
    const isFirstRender = useIsFirstRender()

    const application = useSelector((state: any) => state.signup.application)
    const stepDirection = useSelector((state: any) => state.signup.direction)
    const prevStep = useSelector((state: any) => state.signup.prevStep)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const isLoading = useSelector((state: any) => state.signup.isLoading)

    const industryOptions = taxCategories.map(category => ({ value: category, label: category }))
    const structureOptions = companyStructureList.map(category => ({ value: category, label: messages.BizTypes[category] }))

    const [showErrors, setShowErrors] = useState(false)
    const [errors, setErrors] = useState<any>({})

    const [bizName, setBizName] = useState(application.companyName || '')
    const [dba, setDBA] = useState(application.dbaName || '')
    const [businessStructure, setBusinessStructure] = useState(application.companyStructure ? structureOptions.find(option => option.value === application.companyStructure) : undefined) 
    const [ein, setEin] = useState(application.taxIdentificationNumber || '')
    const [formationDate, setFormationDate] = useState(application.formationDate ? moment(application.formationDate).format('YYYY-MM-DD') : '')
    const [percentOwnership, setPercentOwnership] = useState(Number(application.businessPercentOwnership) ? Number(application.businessPercentOwnership).toLocaleString() : '')
    const [naicsCode, setnaicsCode] = useState(application.naicsCode ? industryOptions.find(option => option.value === application.naicsCode) : undefined)
    const [bizAddress, setBizAddress] = useState<any>(application.businessAddressStreet ? {
        label: `${application.businessAddressStreet}, ${application.businessAddressCity}, ${application.businessAddressState}`,
        value: undefined,
    } : undefined)
    const [bizAddressLine2, setBizAddressLine2] = useState(application.businessAddressLineTwo || '')

    const isSoleProp = application.companyStructure === CompanyStructure.SoleProp

    const handleBack = (ev: React.MouseEvent) => {
        dispatch(updateStep({ step: prevStep, direction: StepDirection.Up }))
    }

    const validateApplication = () => {
        // Business fields
        const businessStructureError = validateBusinessStructure(businessStructure?.value)
        const businessNameError = businessStructure?.value !== CompanyStructure.SoleProp && validateString(bizName)
        const einError = validateEIN(ein, businessStructure?.value)
        const businessAddressError = validateAddress(bizAddress)
        const businessAddressLine2Error = bizAddressLine2.length > 40 ? StringError.TooLong : undefined
        const businessIndustryError = validateBusinessIndustry(naicsCode?.value)
        const businessDBAError = dba !== '' && validateString(dba)
        const formationDateError = validateFormationDate(formationDate)
        const percentOwnershipError = validatePercentOwnership(percentOwnership)

        const isBusinessInfoInvalid = !![
            businessStructureError,
            businessNameError, 
            einError, 
            businessAddressError, 
            businessAddressLine2Error, 
            businessIndustryError, 
            businessDBAError,
            formationDateError
        ].some(error => error !== false && error !== undefined)

        setErrors({
            // business fields
            companyStructure: businessStructureError,
            companyName: businessNameError,
            ein: einError,
            bizAddress: businessAddressError,
            bizAddress2: businessAddressLine2Error,
            naicsCode: businessIndustryError,
            dbaName: businessDBAError,
            formationDate: formationDateError,
            percentOwnership: percentOwnershipError
        })
        return !isBusinessInfoInvalid
    }

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        const isInputValid = validateApplication()

        if (isInputValid) {
            // Geocode biz address
            const parsedBizAddress = await geocodeByPlaceId(bizAddress?.value?.place_id)
                .then((gAddress) => parseGoogleAddress(gAddress))
                .catch(() => undefined)

            const applicationChanges = {
                companyStructure: businessStructure?.value,
                companyName: bizName,
                taxIdentificationNumber: ein,
                businessAddressStreet: parsedBizAddress?.street || application.businessAddressStreet,
                businessAddressCity: parsedBizAddress?.city || application.businessAddressCity,
                businessAddressState: parsedBizAddress?.state || application.businessAddressState,
                businessAddressZip: parsedBizAddress?.zip || application.businessAddressZip,
                businessAddressLineTwo: bizAddressLine2,
                naicsCode: naicsCode?.value,
                dbaName: dba,
                formationDate: formationDate !== '' ? formationDate: undefined,
                businessPercentOwnership: Number(percentOwnership)
            }

            dispatch(updateApplication(applicationChanges))
            dispatch(updateLead({ leadUuid, application: applicationChanges }))
        } else {
            setShowErrors(true)
        }
    }

    // onChange handlers
    const handleStructureSelect = (option: any) => {
        setBusinessStructure(option)
        setErrors({ ...errors, companyStructure: validateBusinessStructure(option?.value) })
    }

    const handleBusinessNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setBizName(ev.target.value)
        setErrors({ ...errors, companyName: validateString(ev.target.value) })
    }
    
    const handleIndustrySelect = (option: any) => {
        setnaicsCode(option)
        setErrors({ ...errors, naicsCode: validateBusinessIndustry(option?.value) })
    }

    const handleEINChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (/[^\d-]/.test(ev.target.value)) return;

        if (ein.includes('-') && !ev.target.value.includes('-')) {
            const cleanedString = ev.target.value.slice(0,1)
            setEin(cleanedString)
            setErrors({ ...errors, ein: validateEIN(cleanedString, businessStructure?.value) })
            return
        }

        const cleaned = ev.target.value.replaceAll('-', '')
        const formatted = cleaned.length >= 2 ? cleaned.slice(0, 2) + '-' + cleaned.slice(2) : cleaned

        if (cleaned.length <= 9) {
            setEin(formatted)
            setErrors({ ...errors, ein: validateEIN(formatted, businessStructure?.value) })
        }
    }

    const handleBizAddressChange = (value: any) => {
        setBizAddress(value)
        setErrors({ ...errors, bizAddress: validateAddress(value) })
    }

    const handleBizAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;

        setBizAddressLine2(ev.target.value)
    }

    const handleDBAChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;
        
        setDBA(ev.target.value)
        setErrors({ ...errors, dbaName: dba !== '' && validateString(ev.target.value) })
    }

    const handleFormationDateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setFormationDate(ev.target.value)
        setErrors({ ...errors, formationDate: validateFormationDate(ev.target.value) })
    }

    const handlePercentChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const asNumber = Number(ev.target.value)
        if (isNaN(asNumber) || asNumber > 100 || asNumber < 0 || ev.target.value.length > 5) return;

        setPercentOwnership(ev.target.value)
        setErrors({ ...errors, percentOwnership: validatePercentOwnership(ev.target.value) })
    }

    // Go back to review page when API is completed
    useEffect(() => {
        const isErrors = Object.values(errors).filter(e => !!e).length > 0
        if (!isFirstRender && isLoading === false && !isErrors) {
            dispatch(updateStep({ step: prevStep, direction: StepDirection.Up, prevStep: SignupStep.EditInfo }))
        }
    }, [isLoading]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='EditInfo'
    >
        <Form onSubmit={handleSubmit}>
            <Title>Edit your business's info.</Title>
            <InputListContainer>
                <InputList>
                    <InputContainer>
                        <Select 
                            errorMessage={showErrors && messages.Errors.BusinessStructure[errors.companyStructure]} 
                            options={structureOptions} 
                            selected={businessStructure} 
                            onSelect={handleStructureSelect} 
                            label='Company Structure'
                        />
                    </InputContainer>
                    <AnimatePresence mode='wait'>
                        {businessStructure?.value !== CompanyStructure.SoleProp && <InputContainer {...inputFadeInOutMotionProps}>
                            <Input 
                                errorMessage={showErrors && messages.Errors.CompanyName[errors.companyName]} 
                                onChange={handleBusinessNameChange}
                                value={bizName} 
                                label="Business Name"
                            />
                        </InputContainer>}
                    </AnimatePresence>
                    <InputContainer>
                        <Input
                            errorMessage={showErrors && messages.Errors.EIN[errors.ein]}
                            onChange={handleEINChange}
                            value={ein}
                            label="EIN"
                        />
                    </InputContainer>
                    <InputContainer>
                        <AddressInput 
                            errorMessage={showErrors && messages.Errors.Address[errors.bizAddress]} 
                            placeholder="Business Address" 
                            onChange={handleBizAddressChange}
                            value={bizAddress}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            autocomplete='nope'
                            errorMessage={showErrors && messages.Errors.Address2[errors.bizAddress2]}
                            onChange={handleBizAddress2Change}
                            value={bizAddressLine2}
                            label="Business Address Line 2*"
                        />
                    </InputContainer>
                </InputList>
                <InputList>
                    <InputContainer>
                        <Select 
                            errorMessage={showErrors && messages.Errors.BusinessIndustry[errors.naicsCode]} 
                            options={industryOptions} 
                            selected={naicsCode} 
                            onSelect={handleIndustrySelect} 
                            label='Industry type'
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            errorMessage={showErrors && messages.Errors.DBA[errors.dbaName]}
                            onChange={handleDBAChange}
                            value={dba} 
                            label="DBA"
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            errorMessage={showErrors && messages.Errors.FormationDate[errors.formationDate]}
                            type='date' 
                            max={moment().format('YYYY-MM-DD')}
                            onChange={handleFormationDateChange}
                            value={formationDate}
                            label="Established Date"
                        />
                    </InputContainer>
                    {!isSoleProp && <InputContainer>
                        <Input
                            errorMessage={showErrors && messages.Errors.PercentOwnership[errors.percentOwnership]} 
                            onChange={handlePercentChange} 
                            value={percentOwnership} 
                            label="% Ownership"
                        />
                    </InputContainer>}
                </InputList>
            </InputListContainer>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button isLoading={isLoading} type="submit">Next</Button>
            </ButtonContainer>
        </Form>
    </StepContainer>
}

const InputListContainer = styled.div`
    max-width: 100%;
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const InputList = styled.div`
    width: 416px;
    &:not(&:last-child) {
        margin-right: 16px;
        ${styles.MediaQueries.Mobile} {
            margin-right: 0 !important;    
            margin-bottom: 12px;
        }
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const InputContainer = styled(motion.div)`
    &:not(&:first-child) {
        margin-top: 12px;
    }
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100dvh - 120px);
    padding: 10px;
    max-width: 100%;
    ${styles.MediaQueries.Mobile} {
        max-height: unset;
        min-height: min-content;
    }
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
        line-height: 124%; /* 29.76px */
        letter-spacing: 0.24px;
    }
`

export default EditBusinessInfo