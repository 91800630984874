import React, { useEffect, useRef, useState } from "react"

import { AnimatePresence, motion } from "framer-motion"
import { styled } from "styled-components"

import { expandVerticallyMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as Eye } from "assets/svg/Eye.svg";
import { ReactComponent as HiddenEye } from "assets/svg/HiddenEye.svg";

type InputProps = {
    parentRef?: any,
    prefix?: React.ReactNode,
    value?: string,
    setValue?: (value: string) => void,
    label?: string,
    showsError?: boolean,
    onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void,
    onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void,
    errorMessage?: string,
    name?: string,
    type?: string,
    pattern?: string,
    inputmode?: string,
    max?: string,
    min?: string,
    autocomplete?: any,
}

const Input = (props: InputProps) => {

    const prefixRef = useRef(null)

    const [isInputFocused, setIsInputFocused] = useState(false)
    const [previewWidth, setPreviewWidth] = useState(0)
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)

    const inputType = (props.type === 'password' && !isPasswordHidden) ? 'text' : props.type
    const isLabelMinified = !!(isInputFocused || (props.value && props.value !== ''))
    const isEmpty = !props.value || props.value === ''

    const handleFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
        props.onFocus && props.onFocus(ev);
        setIsInputFocused(true);
    }

    const handleBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
        props.onBlur && props.onBlur(ev);
        setIsInputFocused(false);
    }

    const toggleIsPasswordHidden = () => {
        setIsPasswordHidden(!isPasswordHidden)
    }

    const prefixWidth = (prefixRef.current as any)?.clientWidth
    useEffect(() => {
        if (prefixRef.current) {
            setPreviewWidth((prefixRef.current as any)?.clientWidth)
        }
    }, [prefixWidth, isLabelMinified])

    return <Container>
        <InputEl
            onFocus={handleFocus}
            onBlur={handleBlur}
            $isinputfocused={isInputFocused}
            ref={props.parentRef}
            value={props.value}
            onChange={props.onChange}
            type={inputType}
            name={props.name}
            error={props.errorMessage}
            $previewwidth={previewWidth}
            $isempty={isEmpty}
            max={props.max}
            min={props.min}
            autoComplete={props.autocomplete}
            pattern={props.pattern}
            inputMode={props.inputmode as any}
        />
        {isLabelMinified && props.prefix && <PrefixContainer ref={prefixRef} isLabelMinified={isLabelMinified}>
            {props.prefix}
        </PrefixContainer>}
        <Label error={props.errorMessage} $isinputfocused={isInputFocused} $islabelminified={isLabelMinified}>{props.label}</Label>
        <PasswordIconContainer>
            {props.type === 'password' && (isPasswordHidden ? <StyledEye onClick={toggleIsPasswordHidden}/> : <StyledHiddenEye onClick={toggleIsPasswordHidden} />)}
        </PasswordIconContainer>
        <AnimatePresence mode='wait'>
            {props.errorMessage && <ErrorMessageContainer {...expandVerticallyMotionProps}>
                <ErrorMessage>{props.errorMessage}</ErrorMessage>
            </ErrorMessageContainer>}
        </AnimatePresence>
    </Container>
}

const ErrorMessageContainer = styled(motion.div)`
    min-width: 100%;
    width: min-content;
`

const StyledHiddenEye = styled(HiddenEye)`
    fill: ${styles.Color.TaekusBlack};
    width: 32px;
    height: 32px;
`

const StyledEye = styled(Eye)`
    padding: 4px;
    fill: ${styles.Color.TaekusBlack};
    width: 32px;
    height: 32px;
`

const PasswordIconContainer = styled.div`
    position: absolute;
    top: 0;
    right: 8px;
    height: 52px;
    display: flex;
    align-items: center;
`

const PrefixContainer = styled.div<any>`
    pointer-events: none;    
    position: absolute;
    top: 0;
    left: 0;
    height: 52px;
    width: 43px;
    padding: ${props => props.isLabelMinified ? '25px' : '10px'} 16px 10px;
    display: flex;
    align-items: center;
    color: ${props => props.isLabelMinified ? styles.Color.TaekusBlack : styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    ${styles.Animation.transitionStyles}
`

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

type ContainerProps = {
    showsError?: boolean,
}

const Container = styled.div<ContainerProps>`
    min-height: 52px;
    position: relative;
`

type LabelProps = {
    $islabelminified: boolean,
    error?: string,
    $isinputfocused: boolean,
}

const Label = styled.div<LabelProps>`
    user-select: none;
    pointer-events: none;
    position: absolute;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    width: 308px;
    height: ${props => props.$islabelminified ? '30px' : '52px'};
    top: 0;
    left: 0;
    ${styles.Animation.transitionStyles}
    color: ${props => props.error ? styles.Color.TaekusRed : (props.$isinputfocused ? styles.Color.TaekusPurple : styles.Color.TaekusGrey3)};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${props => props.$islabelminified ? '10px' : '16px'};
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

type InputElProps = {
    error: any,
    $previewwidth: number | null,
    $isempty: boolean,
    type?: string,
    $isinputfocused: boolean,
}

const InputEl = styled.input<InputElProps>`
    border-radius: 2px;
    border: 1px solid ${props => props.error ? styles.Color.TaekusRed : styles.Color.TaekusGrey4};
    height: 52px;
    padding: 23px 16px 9px ${props => (props.$previewwidth ? props.$previewwidth - 25 : 0) + 16}px;
    outline: 1px solid transparent;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }
    background-color: ${styles.Color.White};
    ${props => props.$isinputfocused && `
        background: linear-gradient(0deg, rgba(124, 61, 118, 0.05) 0%, rgba(124, 61, 118, 0.05) 100%), ${styles.Color.White};
        outline: 1px solid ${props.error ? styles.Color.TaekusRed : styles.Color.TaekusPurple};
        border: 1px solid transparent;
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
    `}
    min-width: 308px;
    width: 100%;
    color: ${styles.Color.TaekusBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.32px;
    &:not(&:focus) {
        ${props => props.type === 'date' && props.$isempty && `
            color: transparent;
            user-select: none;
        `}
    }
    &::-webkit-calendar-picker-indicator {
        display: none;
        &::-webkit-appearance: none;
    }
    &::-webkit-datetime-edit-day-field:focus,
    &::-webkit-datetime-edit-month-field:focus,
    &::-webkit-datetime-edit-year-field:focus {
        background-color: rgba(124, 61, 118, 0.2);
    }
    &::-webkit-date-and-time-value {
        text-align: left;
    }
    appearance: none;
    &::-webkit-appearance: none; /* For Safari and Chrome on iOS */
    &::-moz-appearance: none; /* For Firefox */
    ${styles.Animation.transitionStyles}
`

export default Input